import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { API_BASE_URL } from "src/utils/AppConstants";
import { AppThunkAction } from "..";
import { actionTypes } from "../ActionTypes";

export interface PostAuthStoreState {
    isCompanySfSubscriptionEnabled: boolean;
    isCompanySignaturesProductEnabled:boolean;
    postAuthStoreLoaded: boolean;
    postAuthApiLoading: boolean;
    loggedOut: boolean;
    isTestCompany: boolean;
    companySubscription: string;
}

const initalPostAuthStoreState: PostAuthStoreState = {
    isCompanySfSubscriptionEnabled: true,
    isCompanySignaturesProductEnabled:true,
    postAuthStoreLoaded: false,
    postAuthApiLoading: false,
    loggedOut: false,
    isTestCompany: true,
    companySubscription: ""
}

type KnownAction = ApiLoaderAction | RecieveDataAction | LoggedOutAction | PostAuthStoreLoader;

export interface ApiLoaderAction {
    type: actionTypes.POST_AUTH_STORE_API_LOADER;
    postAuthApiLoading: boolean;
}

export interface RecieveDataAction {
    type: actionTypes.POST_AUTH_STORE_RECEIVE_DATA
    iscompanyProductStatus: any;
}

export interface LoggedOutAction {
    type: actionTypes.LOGGED_OUT
    loggedOut: boolean;
}

export interface PostAuthStoreLoader {
    type: actionTypes.POST_AUTH_STORE_LOADER;
}   

let authDataExists = false;

export const actionCreators = {

    requestPostAuthData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (authDataExists) {
            return;
        }
        
        dispatch({ type: actionTypes.POST_AUTH_STORE_API_LOADER, postAuthApiLoading: true });

        const fetchTask = fetch(`${API_BASE_URL}api/Company/CompanyProductStatus/GetCompanyProductStatus`, { credentials: 'include' })
            .then((resp) => resp.json())
            .then(data => {
                authDataExists = true;
                dispatch({ type: actionTypes.POST_AUTH_STORE_RECEIVE_DATA, iscompanyProductStatus: data })
                dispatch({ type: actionTypes.POST_AUTH_STORE_API_LOADER, postAuthApiLoading: false });
            }).catch((error) => {
                //Temporary fix for the issue where the API giving 403 error
                dispatch({ 
                    type: actionTypes.POST_AUTH_STORE_RECEIVE_DATA,
                    iscompanyProductStatus: {
                            isCompanySignaturesSubscriptionEnabled : true,
                            isCompanySignaturesProductEnabled : false,
                            isTestCompany: true
                        }
                })
                dispatch({ type: actionTypes.POST_AUTH_STORE_API_LOADER, postAuthApiLoading: false });
            }).finally(() => {
                dispatch({ type: actionTypes.POST_AUTH_STORE_LOADER });
              });

        addTask(fetchTask);
    },

    loggedOut: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.LOGGED_OUT, loggedOut: true });
    }
}

export const reducer: Reducer<PostAuthStoreState> = (
    state: PostAuthStoreState = initalPostAuthStoreState,
    incomingAction: Action) => {

    const action = incomingAction as KnownAction;

    switch (action.type) {

        case actionTypes.POST_AUTH_STORE_API_LOADER:
            return { ...state, postAuthApiLoading: action.postAuthApiLoading }

        case actionTypes.POST_AUTH_STORE_RECEIVE_DATA:
            return { 
                ...state,                 
                isCompanySfSubscriptionEnabled: action.iscompanyProductStatus.isCompanySignaturesSubscriptionEnabled,
                isCompanySignaturesProductEnabled:action.iscompanyProductStatus.isCompanySignaturesProductEnabled,
                isTestCompany: action.iscompanyProductStatus.isTestCompany,
                companySubscription: action.iscompanyProductStatus.companySubscription
            }
        case actionTypes.POST_AUTH_STORE_LOADER:
            return { ...state, postAuthStoreLoaded: true }    
        case actionTypes.LOGGED_OUT:
            return { ...state, loggedOut: action.loggedOut }

        default: return state || initalPostAuthStoreState;
    }
}