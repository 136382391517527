import { FirmType } from "src/Core/ViewModels/Company/CompanyViewModel";

 export const injectPendoScript = (props) => {
     const {
        company_id,
        company_name,
        user_id,
        given_name: firstName,
        family_name: lastName,
        email,
    } = props.authState.user?.profile;
    const isTestCompany = props.isTestCompany;
    const companySubscription = props.companySubscription;

    if (!company_id || !company_name || !user_id || !firstName || !lastName || !email) {
        console.log("User profile not available yet");
        return;
    }

    const apiKey = process.env.REACT_APP_PENDO_API_KEY;

    (function (p: any, e: any, n: any, d: any, o: any) {
        var v: any, w: any, x: any, y: any, z: any;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
        for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
                o[m] =
                    o[m] ||
                    function () {
                        o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                    };
            })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo", "");

    window.pendo?.initialize({
        visitor: {
            id: `${company_id}-${user_id}-${email}`,
            email: email,
            firstName: firstName,
            lastName: lastName
        },
        account: {
            id: company_id,
            accountName: company_name,
            FirmType: isTestCompany ? FirmType[FirmType.Internal] : FirmType[FirmType.Live],
            Subscription: companySubscription
        }
    });
};
