import { connect } from "react-redux";
import { ApplicationState } from "src/SignatureFlow/store/types";
import { AppLayout } from "./AppLayout";
import { actionCreators as PostAuthStore } from '../../../store/PostAuth/PostAuthStore';

export default connect(
    (state: ApplicationState) => ({
        authState:state.auth,
        loggedOutState: state.postAuthDataStore.loggedOut,
        isTestCompany: state.postAuthDataStore.isTestCompany,
        companySubscription: state.postAuthDataStore.companySubscription
    }),{
        ...PostAuthStore
    }
    )(AppLayout)